<template>
  <div class="calculator-results" :class="isFundingPlan && 'funding-plan'">
    <div class="card total-card" v-if="!isFundingPlan">
      <div class="result-row">
        <h1>{{ results.buyingCapacity | price }}*</h1>
      </div>
      <div class="borders" />
      <div class="result-config" v-if="city">
        <h3>Trouver votre future maison sur la commune de {{ city.city.toUpperCase() }}</h3>
        <app-button hasArrow theme="primary" size="large" @click="$emit('showConfig', city)">Configurer</app-button>
      </div>
    </div>
    <div class="card">
      <div class="header-row">
        <h5>RAPPEL DES DONNÉES SAISIES</h5>
      </div>
      <div class="row">
        <p>Apport personnel</p>
        <p>{{ results.deposit | price }}</p>
      </div>
      <div class="row" v-if="!isFundingPlan">
        <p>Mensualité souhaitée</p>
        <p>{{ results.monthlyPaymentMax | price }}</p>
      </div>
      <div class="row" v-else>
        <p>Revenu annuel net du ménage</p>
        <p>{{ results.incomeN | price }}</p>
      </div>
      <div class="row">
        <p>Revenu fiscal de référence en {{ yearN1 }}</p>
        <p>{{ results.incomeN2 | price }}</p>
      </div>
      <div class="row">
        <p>Nombres de personnes dans le ménage</p>
        <p>{{ results.familySize }}</p>
      </div>
    </div>
    <div class="card">
      <div class="header-row">
        <h5>DÉTAILS DU FINANCEMENT</h5>
      </div>
      <div class="row">
        <p>Montant du prêt principal</p>
        <p>{{ results.primaryLoanAmount | price }}</p>
      </div>
      <div class="row">
        <p>Durée de prêt principal {{ isDurationAdjusted ? '**' : '' }}</p>
        <p>{{ results.duration }} ans</p>
      </div>
      <div class="row">
        <p>Taux proposé</p>
        <p>{{ results.interestRate }} %</p>
      </div>
      <div class="row">
        <p>Coût du crédit hors assurance</p>
        <p>{{ results.primaryLoanCoast | price }}</p>
      </div>
    </div>
    <div class="card">
      <div class="header-row">
        <h5>PRÊT À TAUX ZÉRO (PTZ)</h5>
      </div>
      <div class="row">
        <p>Éligibilité</p>
        <p>{{ results.ptzEligibility ? 'OUI' : 'NON' }}</p>
      </div>
      <div class="row">
        <p>Montant estimé</p>
        <p>{{ results.ptzLoanAmount || 0 | price }}</p>
      </div>
      <div class="row">
        <p>Durée du différé</p>
        <p>{{ results.ptzLoanDifferedMonth || 0 }} mois</p>
      </div>
      <div class="row">
        <p>Durée d'amortissement</p>
        <p>{{ results.ptzLoanPayingMonth || 0}} mois</p>
      </div>
        <h3 class="monthly-price" v-if="isFundingPlan">{{ results.monthlyPayment | price }} / mois *</h3>
    </div>
    <app-button theme="secondary" @click="$emit('click')">Modifier ma simulation</app-button>
    <aside :class="{ 'text-black': $route.name.includes('calculator-budget') }">
      <p>*Mensualité hors assurance. Le résultat issu du configurateur de projet et le coût total estimé du projet sont fonctions des informations que l’utilisateur a renseignées et des options et équipements retenus par ce dernier ou indiqués par défaut. Il ne saurait être interprété comme tout document ayant force contractuelle.</p>
      <p>Cette approche financière vous est donnée à titre indicatif. Maisons Alysia se réserve le droit de modifier ou de mettre à jour les composantes de cette simulation financière, comme de vous proposer de réétudier votre simulation en cas de modification du dispositif du prêt à taux zéro par l'Etat.</p>
      <p v-if="isDurationAdjusted">**Durée ajustée</p>
    </aside>
  </div>
</template>

<script>
import postalCodeApi from '@/services/api/postalCode';

export default {
  name: 'calculator-results',
  props: {
    results: {
      type: Object,
      required: true,
    },
    isFundingPlan: {
      type: Boolean,
      default: false,
    },
    originalDuration: {
      type: Number,
    },
  },
  emits: ['click'],
  data() {
    return {
      city: null,
      yearN1: new Date().getFullYear() - 1,
    };
  },
  mounted() {
    this.getCity();
  },
  methods: {
    async getCity() {
      this.city = await postalCodeApi.getOne(this.results.zipCode);
    },
  },
  computed: {
    isDurationAdjusted() {
      return this.originalDuration && this.results.duration && this.results.duration !== this.originalDuration;
    },
  },
  filters: {
    price(value) {
      const intl = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 });
      const text = intl.format(value).replace(/[\u202F\u00A0]/g, ' '); // Remplace les 'non-breaking spaces' par de vrais espaces pour + de lisibilité
      return text;
    },
  },
};
</script>

<style lang="sass">
.calculator-results
  max-width: 520px
  .card
    @include card
    padding: 0
    margin: 1rem auto
    .header-row, .row
      padding: 1rem
      p, h5
        color: $subtitle
    .row
      display: flex
      justify-content: space-between
      border-top: 1px solid $line
      padding-left: 2rem
      padding-right: 2rem
      p:last-child
        font-weight: 700
      p:first-child
        max-width: 65%
      p:first-child:last-child
        width: 100%
        max-width: unset
        text-align: center
  .result-row
    background-color: $error
    position: relative
    text-align: center
    padding: 48px 0 32px 0
    border-radius: 8px 8px 0 0
    h1
      font-size: 60px
      color: $white
  .monthly-price
    text-align: center!important
  @media (max-width: 550px)
    .borders
      position: relative
      z-index: 2
      border-top: 50px solid $error
      border-left: 46vw solid $colored-bg
      border-right: 46vw solid $colored-bg
  @media (min-width: 550px)
    .borders
      position: relative
      z-index: 2
      border-top: 50px solid $error
      border-left: 260px solid $colored-bg
      border-right: 260px solid $colored-bg
  .result-config
    position: relative
    background-color: $colored-bg
    text-align: center
    padding: 48px 0 16px 0
    border-radius: 0 0 8px 8px
    h3
      font-size: 24px
      margin: 0 auto
      max-width: 80%
      color: $white
    .app-button
      padding: 16px 32px
      span
        font-size: 18px !important
  .total-card
    margin: 0 0 48px 0
    .row:first-child
      border-top: none
  .app-button
    color: $primary !important
    margin: 2rem auto
  aside
    margin: 1rem 0
    p
      font-size: 12px
      color: $white
      line-height: 18px
      text-align: justify
    a
      font-size: 12px
      color: $primary
      text-decoration: none
    p:nth-of-type(3)
      margin-top: 1rem
    &.text-black
      p
        color: $black
  &.funding-plan
    .card
      box-shadow: none
    .app-button
      color: $white !important
</style>
