<template>
  <div>
    <div class="mobile-carousel">
      <search class="search" @click="fullScreen = true" />
      <carousel
        class="carousel"
        :per-page="1"
        paginationPosition="bottom-overlay"
        paginationActiveColor="#1BBAC8"
        :paginationSize="7"
        :paginationPadding="3"
      >
        <slide class="slide" v-for="(image, index) in orderedImages" :key="image.versionMediaId || index">
          <div class="video" v-if="image.type === 'VIDEO'">
            <iframe
              :src="image.url"
              width="100%"
              height="100%"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
            <div class="overlay-left"></div>
            <div class="overlay-right"></div>
            <div class="overlay-controls"></div>
          </div>
          <img v-else :src="image.url" :class="image.type" />
        </slide>
      </carousel>
    </div>
    <!-- Open carousel in full screen -->
    <div class="mobile-carousel-full-screen" v-if="fullScreen">
      <close class="close" @click="fullScreen = false"/>
      <carousel
        class="carousel"
        :per-page="1"
        paginationActiveColor="#1BBAC8"
        :paginationSize="7"
        :paginationPadding="3"
      >
        <slide v-for="(image, index) in orderedImages" :key="index">
          <img :src="image.url" :class="image.type" />
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'; // Use of this package : https://github.com/SSENSE/vue-carousel
import search from '@/assets/img/search-slider-mobile.svg?inline';
import close from '@/assets/img/close.svg?inline';

export default {
  name: 'mobile-carousel',
  components: {
    Carousel,
    Slide,
    search,
    close,
  },
  props: {
    images: {
      type: Array,
    },
  },
  data() {
    return {
      orderedImages: null,
      fullScreen: false,
    };
  },
  mounted() {
    this.orderImages();
  },
  methods: {
    // On réordonne les images pour avoir une image de l'extérieur en premier
    orderImages() {
      const images = [...this.images];

      // Séparer les images en fonction de leur type
      const exteriorImages = images.filter((img) => img.type === 'EXTERIOR_IMAGE');
      const blueprints = images.filter((img) => img.type === 'BLUEPRINT');
      const videos = images.filter((img) => img.type === 'VIDEO');
      const mainImages = images.filter((img) => img.type === 'MAIN_IMAGE');
      const secondaryImages = images.filter((img) => img.type === 'SECONDARY_IMAGE');

      // Extraire la première image extérieure (Perspective ext. 1)
      const firstExteriorImage = exteriorImages.length > 0 ? exteriorImages.shift() : null;

      // Construire l'ordre final
      this.orderedImages = [
        firstExteriorImage,
        ...blueprints,
        ...exteriorImages,
        ...videos,
        ...mainImages,
        ...secondaryImages,
      ].filter(Boolean);
    },
  },
};
</script>

<style lang='sass'>
.mobile-carousel
  position: relative
  .slide
    position: relative
  .search
    position: absolute
    top: 8px
    right: 8px
    z-index: 3
  img
    width: 100%
    height: auto
    aspect-ratio: 1.51 / 1
    object-fit: cover
    &.BLUEPRINT, &.SECONDARY_IMAGE
      aspect-ratio: 16 / 9
      object-fit: contain
  .VueCarousel-pagination
    margin-bottom: 10px
  .video
    position: relative
    height: 100%
    .overlay-left, .overlay-right
      position: absolute
      top: 0
      bottom: 0
      width: 30%
    .overlay-left
      left: 0
    .overlay-right
      right: 0
    .overlay-controls
      position: absolute
      bottom: 0
      left: 0
      right: 0
      height: 40px
      background: black
.mobile-carousel-full-screen
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: black
  z-index: 11
  .close
    position: absolute
    top: 29px
    right: 29px
    cursor: pointer
    width: 14px
    height: 14px
    stroke: white
    stroke-width: 2
    z-index: 12
  .carousel
    display: flex
    justify-content: center
    align-items: center
    height: 100%
    img
      width: 100%
      height: auto
      &.BLUEPRINT, &.SECONDARY_IMAGE
        aspect-ratio: 16 / 9
        object-fit: contain
    .VueCarousel-pagination
      position: absolute
      bottom: 25px
</style>
